<template>
  <div style="margin: 10px; font-size: 12px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <h3>Yeni tedavi oluştur</h3>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentschemaselection.png" width="100%">
        </div>
        <div style="width: 100%;">
          <img src="../../../public/demo/treatmentschemadrugs.png" width="100%">
        </div>
        <b-row>
          <b-col sm="12" lg="10">
          </b-col>
          <b-col sm="12" lg="2">
            <b-button variant="secondary" size="md" @click="f_saveTreatment()" title="Tedaviyi Hastaya Kaydetmek İçin Tıklayınız."> <i class="fa fa-plus"></i> Kaydet </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as Gfr
} from '@/components/widgets/Gfr';
import {
  default as Bsa
} from '@/components/widgets/Bsa';
import {
  default as DiagnosisSelection
} from '@/components/widgets/DiagnosisSelection';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'NewTreatment',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    Gfr,
    Bsa,
    DiagnosisSelection,
    SqlDatabase
  },
  props: {},
  data () {
    return {
      d_addHr: true,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_diagnosisData: {
        'selected': ''
      },
      d_selectedSchema: {
        'schema': '',
        'schema_group': '',
        'schema_type': '',
      },
      d_schemaData: {
        'drugs': [{
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 100,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "before", // before, after, during, drug, mix
          "relation_index": 2,
          "drug_order": 1,
          "fluid": {
            "value": "1",
            "label": "100 cc %0.9 sodium chloride"
          },
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "1",
            "label": "Deksametazon"
          },
          "duration_wait": 0,
          "dosage": 8,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }, {
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 0,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "mix", // before, after, during, drug, mix
          "relation_index": 0,
          "drug_order": 1,
          "fluid": "",
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "3",
            "label": "Granisetron"
          },
          "duration_wait": 0,
          "dosage": 1,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }, {
          "period": 21,
          "frequency_first": 1,
          "frequency_second": 1,
          "fluid_value": 100,
          "duration": 10,
          "unit": {
            "value": "mg",
            "label": "mg"
          },
          "drug_note": "",
          "delay": 0,
          "recipe_data": {},
          "type": "drug", // before, after, during, drug, mix
          "relation_index": "",
          "drug_order": 1,
          "fluid": {
            "value": "1",
            "label": "100 cc %0.9 sodium chloride"
          },
          "day": [1, 8],
          "repeat": 3,
          "drug": {
            "value": "2",
            "label": "Paklitaksel"
          },
          "duration_wait": 0,
          "dosage": 8,
          "dosage_form": {
            "value": "12",
            "label": "İntravenöz"
          }
        }],
        'name': 'dosetaksel (100 mg/m2 , 1.gün)  [21 gündebir , 3kür]',
        'reference': 'Sequential adjuvant epirubicin-based and docetaxel chemotherapy for node-positive breast cancer patients: the FNCLCC PACS 01 Trial.'
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

